<template>
    <h1>Max's reaction trainer</h1>
    <button @click="start" :disabled="isPlaying">Begin</button>
    <Block v-if="isPlaying" :delay="delay" @end="endGame" />
    <Results v-if="scores && !isPlaying" :scores="scores" />
</template>

<script>
import Block from './components/Block.vue';
import Results from './components/Results.vue';

export default {
    name: 'App',
    components: {
        Block, Results
    },
    data() {
        return {
            isPlaying: false,
            delay: null,
            scores: []
        }
    },
    methods: {
        start() {
            this.delay = 2000 + Math.random() * 5000;
            this.isPlaying = true;
            this.score = null;
        },
        endGame(reactTime) {
            this.scores.push(reactTime / 1000);
            this.scores = this.scores.sort((a, b) => a - b).slice(0, 10);
            this.isPlaying = false;
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #444;
    margin-top: 60px;
}

button {
    background: #0faf87;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 10px;
}

button[disabled] {
    opacity: 0.2;
    cursor: not-allowed;
}
</style>
